import { A, cache, createAsync, useNavigate } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createSignal,
  For,
  onCleanup,
  onMount,
  Show,
  Suspense,
} from "solid-js";
import { config } from "~/data/config";
import { getSquidProformaInvoicesRouteData } from "~/server/data/squid_proforma_invoices_route_data";
import { SquidProformaInvoicesResponse } from "~/server/types/order";
import { Divider } from "~/widgets/divider";
import { EmptyState } from "~/widgets/empty_state";
import { CircularLoader, DottedLoader } from "~/widgets/loader";
import { formatDateToDDMMMMYYYY } from "~/utils/date";
import {
  arrowLeftIcon,
  piEmptyState,
  threeDotsVerticalIcon,
} from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";
import { clientRepo } from "~/server/apis/client_repo";
import { toIndianNumber } from "~/utils/number";
import { Tooltip } from "~/widgets/tooltip";
import { showSnackBar } from "~/shared_states/snackbar";
import { useSquidContext } from "~/context/squid_context";
import { Optional } from "~/types";

const getSquidProformaInvoicesRouteData$C = cache(
  getSquidProformaInvoicesRouteData,
  "squid_proforma_invoices"
);

export default function ProformaInvoice() {
  const routeData: Accessor<SquidProformaInvoicesResponse | undefined> =
    createAsync<SquidProformaInvoicesResponse>(() => {
      return getSquidProformaInvoicesRouteData$C();
    });
  const [activeRow, setActiveRow] = createSignal<string>("");
  const { updatePIInfo, clearCart, clearAndAddProductsToCart } =
    useSquidContext();
  const navigator = useNavigate();
  const [isProceedingToCheckout, setIsProceedingToCheckout] =
    createSignal(false);
  const [sendingEmail, setSendingEmail] = createSignal<string[]>([]);

  // ===================================================================
  // Setup to handle clicks on the screen and close any active overlays
  // ===================================================================
  const [closeOverlaysNonce, setCloseOverlaysNonce] = createSignal<number>(1);

  const handleClickOutside = (event: MouseEvent) => {
    const overlayTriggerAreas = document.querySelectorAll(".overlay-trigger");
    const isTriggeringOverlay = Array.from(overlayTriggerAreas).some(
      (overlayTriggerArea) => overlayTriggerArea.contains(event.target as Node)
    );
    if (isTriggeringOverlay) return;

    const overlays = document.querySelectorAll(".overlay");
    const isAnyOverlayOpen = overlays.length > 0;
    const isClickInsideOverlay = Array.from(overlays).some((overlay) =>
      overlay.contains(event.target as Node)
    );

    if (!isClickInsideOverlay && isAnyOverlayOpen) {
      setCloseOverlaysNonce(closeOverlaysNonce() + 1);
      event.stopPropagation();
    }
  };

  onMount(() => {
    document.addEventListener("click", handleClickOutside);
    onCleanup(() => {
      document.removeEventListener("click", handleClickOutside);
    });
  });
  // ===================================================================
  // End - Setup to handle clicks on the screen and close any active overlays
  // ===================================================================

  const sendPIEmail = async (props: { piID: string }) => {
    setSendingEmail((prev) => [...prev, props.piID]);
    try {
      await clientRepo.sendPIOverEmail({ piID: props.piID });
      showSnackBar({
        level: "success",
        message: "email sent to your registered email-id",
      });
    } catch (e: any) {
      showSnackBar({
        level: "error",
        message: e.message,
      });
    }
    setSendingEmail((prev) => prev.filter((id) => id !== props.piID));
  };

  onCleanup(() => {
    if (!isProceedingToCheckout()) {
      clearCart();
    }
  });

  return (
    <Suspense
      fallback={
        <div class="flex w-full items-center justify-center">
          <DottedLoader color="#999" from="squid proforma suspense" />
        </div>
      }
    >
      <div class="w-full">
        <div class="fixed z-10 flex h-[52px] w-full items-center border border-b border-baseTertiaryMedium bg-whiteBg py-4 pl-1 pr-5 text-bold text-textDark sm:sticky lg:h-auto lg:border-none lg:px-5 lg:py-4 lg:text-h5">
          <img
            src={arrowLeftIcon}
            class="cursor-pointer px-2.5 lg:hidden"
            alt="back"
            onClick={() => navigator(-1)}
          />
          Proforma invoice
        </div>
        <div class="hidden lg:block">
          <Divider />
        </div>
        <div class="mt-12 flex flex-col items-center justify-center gap-3 bg-baseTertiaryMedium p-4 lg:hidden">
          <Show
            when={routeData()?.data && routeData()?.data?.length! > 0}
            fallback={emptyState()}
          >
            <PICards closeOverlaysNonce={closeOverlaysNonce} />
          </Show>
        </div>
        <div class="hidden lg:block">
          <Show
            when={routeData()?.data && routeData()?.data?.length! > 0}
            fallback={emptyState()}
          >
            {PITable()}
          </Show>
        </div>
      </div>
    </Suspense>
  );

  function PICards(props: { closeOverlaysNonce: Accessor<number> }) {
    const [overlayOpenForPIId, setOverlayOpenForPIId] =
      createSignal<Optional<string>>(null);

    createEffect(() => {
      if (props.closeOverlaysNonce()) {
        setOverlayOpenForPIId(null);
      }
    });

    const toggleDropdown = (pIId: string) => {
      setOverlayOpenForPIId(pIId);
    };

    return (
      <For each={routeData()?.data}>
        {(PI) => {
          return (
            <div class="relative w-[343px] rounded-xl border border-baseTertiaryDark bg-white p-4">
              <div
                class="overlay-trigger absolute right-1 top-1 flex h-[37px] w-[37px] cursor-pointer items-center justify-center rounded-full"
                classList={{
                  "bg-baseTertiaryMedium": PI.id === overlayOpenForPIId(),
                }}
                onClick={() => toggleDropdown(PI.id)}
              >
                <img src={threeDotsVerticalIcon} alt="" />
              </div>
              <Show when={PI.id === overlayOpenForPIId()}>
                <div class="overlay absolute right-0 top-11 h-[55px] w-[184px] rounded-lg border border-baseTertiaryDark bg-white py-2 [box-shadow:0px_2px_20px_0px_rgba(0,_0,_0,_0.10)]">
                  <div
                    class="flex h-[39px] items-center px-4 text-mediumBold text-textDark"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (sendingEmail().includes(PI.id)) {
                        return;
                      }
                      sendPIEmail({ piID: PI.id });
                    }}
                  >
                    Send PI over mail
                  </div>
                </div>
              </Show>
              <div class="flex flex-col gap-2">
                <div class="text-mediumBold text-textDark">
                  ID: {PI.proformaInvoiceId}
                </div>
                <div class="text-mediumBold text-textDark">
                  {formatDateToDDMMMMYYYY(new Date(PI.date))}
                </div>
                <div class="text-medium text-textDark">
                  {PI.products.length > 3
                    ? PI.products
                        .slice(0, 3)
                        .map((item) => item.productName)
                        .join(", ")
                        .toString() +
                      " +" +
                      (PI.products.length - 3) +
                      " more"
                    : PI.products.map((item) => item.productName).join(", ")}
                </div>
                <Show when={PI.notes}>
                  <div class="text-medium italic text-textNormal">
                    Note: {PI.notes}
                  </div>
                </Show>
              </div>
              <hr class="my-4 w-full border-t border-baseTertiaryMedium" />
              <div class=" flex flex-col gap-3">
                <div class="flex justify-between">
                  <p class="text-mediumBold text-textDark">Total amount</p>
                  <p class="text-medium text-textDark">
                    ₹{toIndianNumber(PI.totalAmount)}
                  </p>
                </div>
                <div class="flex gap-3">
                  <button
                    onClick={() => {
                      window.open(
                        `${config.receiptUrl}/proforma-invoice/${encodeURIComponent(PI.id)}`,
                        "_blank"
                      );
                    }}
                    class="flex h-9 w-full items-center justify-center gap-1 rounded-lg border border-basePrimaryDark px-4 py-2.5 text-mediumBold text-textDark"
                  >
                    Download PI
                  </button>
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsProceedingToCheckout(true);
                      clearAndAddProductsToCart(PI.products);
                      updatePIInfo(PI.id);
                      navigator("/checkout");
                    }}
                    class="flex h-9 w-full items-center justify-center gap-1 rounded-lg bg-basePrimaryDark px-4 py-2.5 text-mediumBold text-white"
                  >
                    Complete order
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      </For>
    );
  }

  function PITable() {
    return (
      <div class="h-[90vh] overflow-auto p-4 pb-10">
        <div class="shadow rounded-lg border border-baseTertiaryMedium bg-white">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="sticky -top-5 z-10  rounded-full bg-gray-50">
              <tr>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  ID
                </th>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  DATE
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  NOTE
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  AMOUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  BRANDS
                </th>
              </tr>
            </thead>
            <tbody class=" divide-y divide-gray-200  rounded-full bg-white ">
              {routeData()?.data.map((PI) => (
                <tr
                  class={` text-medium text-textDark  last:rounded-b-lg hover:cursor-pointer   hover:bg-baseTertiaryMedium`}
                  onMouseEnter={() => {
                    setActiveRow(PI.proformaInvoiceId);
                  }}
                  onMouseLeave={() => {
                    setActiveRow("");
                  }}
                >
                  <td class="whitespace-nowrap border-r p-3 text-mediumBold last:rounded-bl-lg">
                    <div>{PI.proformaInvoiceId}</div>
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    {formatDateToDDMMMMYYYY(new Date(PI.date))}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    <div>
                      {PI.notes?.length > 0 ? (
                        <Tooltip
                          parent={
                            <div class="max-w-[132px] overflow-hidden text-ellipsis">
                              {PI.notes}
                            </div>
                          }
                          tooltip={
                            <div class="mt-2 rounded bg-black px-2 py-1 text-textWhite">
                              {PI.notes}
                            </div>
                          }
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    ₹{toIndianNumber(PI.dueAmount)}
                  </td>

                  <td class="relative whitespace-nowrap border-r p-3">
                    <div class="flex items-center justify-center">
                      <div class="grow">
                        {PI.products.length > 4
                          ? PI.products
                              .slice(0, 4)
                              .map((item) => item.productName)
                              .join(", ")
                              .toString() +
                            " +" +
                            (PI.products.length - 4) +
                            " more"
                          : PI.products
                              .map((item) => item.productName)
                              .join(", ")}
                      </div>
                      <Show when={activeRow() === PI.proformaInvoiceId}>
                        <div class="absolute  right-0 flex items-center justify-center pr-3">
                          <Tooltip
                            parent={
                              <div
                                class="flex h-7 w-7 items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (sendingEmail().includes(PI.id)) {
                                    return;
                                  }
                                  sendPIEmail({ piID: PI.id });
                                }}
                              >
                                <Show
                                  when={sendingEmail().includes(PI.id)}
                                  fallback={
                                    <PhosphorIcon
                                      name="envelope"
                                      fontSize={20}
                                    />
                                  }
                                >
                                  <CircularLoader size={18} />
                                </Show>
                              </div>
                            }
                            tooltip={
                              <div class="mt-1 rounded bg-black px-2 py-1  text-medium text-white">
                                {" "}
                                Send PI over mail
                              </div>
                            }
                          />
                          <Tooltip
                            parent={
                              <div
                                class="mx-2 flex  h-7 w-7 items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white"
                                onClick={() => {
                                  window.open(
                                    `${config.receiptUrl}/proforma-invoice/${encodeURIComponent(PI.id)}`,
                                    "_blank"
                                  );
                                }}
                              >
                                <PhosphorIcon name="file-pdf" fontSize={20} />
                              </div>
                            }
                            tooltip={
                              <div class="mt-1 rounded bg-black px-2 py-1  text-medium text-white">
                                {" "}
                                View PI
                              </div>
                            }
                          />

                          <div
                            class=" h-7 content-center items-center rounded-[8px] bg-black px-3 text-mediumBold text-white"
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsProceedingToCheckout(true);
                              clearAndAddProductsToCart(PI.products);
                              updatePIInfo(PI.id);
                              navigator("/checkout");
                            }}
                          >
                            Complete order
                          </div>
                        </div>
                      </Show>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function emptyState() {
    return (
      <EmptyState
        primaryText="Proforma invoices (PI) you generate will appear here"
        secondaryText="No PI generated yet"
        imageUrl={piEmptyState}
      />
    );
  }
}
